@import "~styles/store/variables";

.hide {
  display: none; }

.instrumentIcon img {
  margin: 0 auto 25px;
  height: 55px;

  @media screen and (max-width: $mobile) {
    height: 35px;
    margin: 0 auto 7px; }

  &:hover {
    filter: saturate(1.5) brightness(.9); } }
