@import "~styles/store/variables.scss";

.slide {
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 100%;

    img {
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      object-fit: scale-down; } }

.topSliderBanner {

  &:hover {
     :global {
      .slick-prev, .slick-next {
        @media screen and (min-width: $tablet) {
         opacity: 1; } } } }


   :global {
    .slick-prev, .slick-next {
      z-index: 1;
      opacity: 0;
      transition: .5s all; } } }

