@import "~styles/store/variables";
@import "~styles/store/helpers";

.sliderContainer {}

.slider {
  width: 283px;

  @media screen and ( max-width: $smallDesktop) {
    width: 220px; }

  @media screen and ( max-width: $tablet) {
    width: 207px; }

  @media screen and ( max-width: $mobile) {
    width: auto; } }

.sliderContainerCenter {
  width: 540px;

  @media screen and ( max-width: $smallDesktop) {
    width: 440px; }

  @media screen and ( max-width: $tablet) {
    width: 227px; }

  @media screen and ( max-width: $mobile) {
    width: auto; } }

.sliderCenter {
  width: 500px;
  padding: 0 10px;

  @media screen and ( max-width: $smallDesktop) {
    width: 420px; }

  @media screen and ( max-width: $tablet) {
    width: 207px; }

  @media screen and ( max-width: $mobile) {
    width: auto; } }

.slide {
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 100%;

    img {
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      object-fit: cover; } }
